//import ContactForm from '../components/ContactForm.js';

function Contact() {
    return (
        <div>
            {/* <ContactForm></ContactForm> */}
            <h1>More coming soon!</h1>
            <p>For now, you can reach me at my email: <a href="mailto:charlymuchmore@gmail.com">charlymuchmore@gmail.com</a></p>
        </div>
    )
}

export default Contact;